import { Button, DateInput, Modal, TextInput } from '@octano/global-ui';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { Period } from '../../type';
import { usePeriodsLoader } from '../Loaders/PeriodsLoader';
import { useValidations } from '../../../../../hooks/useValidations';
import dayjs from 'dayjs';

interface Props {
  actions: {
    createPeriod: (data: any) => Promise<void>;
    updatePeriod: (params: { periodId: number; data: any }) => Promise<void>;
  };
}

export interface CreateOrUpdateRef {
  closeModal: () => void;
  openModal: (row?: Period) => void;
}
interface FormData {
  code: string;
  name: string;
  startDate: string;
  endDate: string;
  description?: string;
}

const CreateOrUpdateModal = forwardRef<CreateOrUpdateRef, Props>(
  ({ actions }, ref) => {
    const { t } = useTranslation();
    const forms = useForm<FormData>();
    const {
      control,
      reset,
      handleSubmit,
      formState: { isSubmitting },
    } = forms;
    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState<Period | null>(null);
    const { data } = usePeriodsLoader();
    const codes = data?.data.map((period) => period.code) ?? [];
    const { validateBeforeDate } = useValidations();

    useEffect(() => {
      if (formData) {
        reset({
          code: formData.code,
          name: formData.name,
          startDate: formData.startDate,
          endDate: formData.endDate,
          description: formData.description ?? undefined,
        });
      } else
        reset({
          code: undefined,
          name: undefined,
          startDate: undefined,
          endDate: undefined,
          description: undefined,
        });
    }, [formData, reset]);

    useImperativeHandle(ref, () => ({
      openModal(row: any) {
        setFormData(row);
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    const onSubmit = async (values: FormData) => {
      const formattedStartDate = dayjs(values.startDate).format('YYYY-MM-DD');
      const formattedEndDate = dayjs(values.endDate).format('YYYY-MM-DD');

      const data = {
        id: formData ? formData.id : undefined,
        code: values.code,
        name: values.name,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        description: values.description,
      };
      if (formData) {
        await actions.updatePeriod({
          periodId: formData.id,
          data,
        });
      } else {
        await actions.createPeriod(data);
      }

      reset();
      setModal(false);
    };

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid>
          <h1 className="text-dark fs-20 mt-3 mb-4 text-center">
            {formData ? 'Editar Período' : 'Ingresar nuevo Período'}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6} xs={12}>
                <TextInput
                  name="code"
                  label={'Código'}
                  placeholder={''}
                  control={control}
                  // validar unique de code usando la variable codes
                  rules={{
                    required: t('common.validations.required'),
                    validate: (value) =>
                      value && codes.includes(value) && formData?.code !== value
                        ? t('El código ya se encuentra en uso')
                        : true,
                  }}
                />
              </Col>
              <Col md={6} xs={12}>
                <TextInput
                  name="name"
                  label={'Nombre'}
                  placeholder={''}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>
              <Col md={6} xs={12}>
                <DateInput
                  name="startDate"
                  label={'Fecha de inicio'}
                  control={control}
                  rules={{
                    required: t('common.validations.required'),
                    validate: (value) => {
                      const endDate = forms.getValues('endDate');
                      return validateBeforeDate(endDate)(value);
                    },
                  }}
                />
              </Col>
              <Col md={6} xs={12}>
                <DateInput
                  name="endDate"
                  label={'Fecha de término'}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: '20px' }}>
                <label htmlFor="description">Descripción (Opcional)</label>
                <textarea
                  rows={4}
                  {...control.register('description')}
                  className="form-control"
                />
              </Col>
              <hr className="py-5" />
              <Col xs={12} md={6}>
                <Button
                  outlined
                  text={'Cancelar'}
                  fullwidth
                  onClick={() => setModal(false)}
                  className="mb-3"
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  type="submit"
                  text={'Guardar'}
                  className="mb-3"
                  fullwidth
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </form>
        </Container>
      </Modal>
    );
  },
);

export default CreateOrUpdateModal;
