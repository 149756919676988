import { SelectOptionType } from '@octano/global-ui';
import { useEffect, useState } from 'react';
import { Commune, Region } from '../type';

/**
 * Obtiene el listado filtrado de opciones de comunas
 * según la región seleccionada
 * @param selectedRegion
 * @returns listado de opciones de comunas
 */
export const useFilteredCommunes = (
  selectedRegion: SelectOptionType | undefined,
  communesOptions: Commune[],
) => {
  const [filteredCommunes, setFilteredCommunes] = useState<Commune[]>([]);

  useEffect(() => {
    const communes = communesOptions.filter(
      (commune) => commune.regionId === selectedRegion?.value,
    );
    setFilteredCommunes(communes);
  }, [selectedRegion, communesOptions]);

  return filteredCommunes.map((commune) => ({
    label: commune.name,
    value: commune.id,
  }));
};
