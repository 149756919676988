import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import Periods from './periods';
import { useLocation, useHistory } from 'react-router-dom';
import { PathsLayouts } from '../../config/routes';
import Campuses from './campuses';

interface TabComponentProps {
  currentTab: string;
  onTabChange: (tabId: string | number) => void;
}

const TabComponent = memo(({ currentTab, onTabChange }: TabComponentProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'views.maintainers',
  });

  return (
    <Card className="mt-5">
      <Tabs defaultTab={currentTab} onSelection={onTabChange}>
        <TabsList>
          <Tab key="periods" id="periods">
            {t('periods.title')}
          </Tab>
          <Tab key="campuses" id="campuses">
            {t('campuses.title')}
          </Tab>
        </TabsList>
        <TabPanels>
          <TabPanel key="periods" id="periods">
            <Periods />
          </TabPanel>
          <TabPanel key="campuses" id="campuses">
            <Campuses />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
});

function GeneralMaintainer() {
  const location = useLocation();
  const history = useHistory();

  const getTabFromPath = useCallback(() => {
    const pathSegment = location.pathname.split('/').pop();
    return pathSegment && pathSegment !== 'general' ? pathSegment : 'periods';
  }, [location.pathname]);

  const [currentTab, setCurrentTab] = useState(getTabFromPath);

  const handleTabChange = useCallback(
    (tabId: string | number) => {
      if (tabId !== currentTab) {
        const routeToGo = `${PathsLayouts.maintainer}/general/${tabId}`;
        history.replace(routeToGo);
        setCurrentTab(tabId as string);
      }
    },
    [currentTab, history],
  );

  useEffect(() => {
    setCurrentTab(getTabFromPath());
  }, [location.pathname, getTabFromPath]);

  return <TabComponent currentTab={currentTab} onTabChange={handleTabChange} />;
}

export default GeneralMaintainer;
