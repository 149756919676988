import { useCallback } from 'react';
import { createCampus, updateCampus, deleteCampus } from '../api';
import { useCampusesFilterLoader } from '../parts/Loaders/CampusesFilterLoader';

interface UseActionsParams {
  createCampus: any;
  updateCampus: { campusId: number; data: any };
}
type ActionType =
  | 'create'
  | 'update'
  | 'delete'
  | 'Sede creada con éxito'
  | 'Sede modificada con éxito'
  | 'Sede eliminada con éxito';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const { data } = useCampusesFilterLoader();

  const createCampusAction = useCallback(
    async (data: UseActionsParams['createCampus']) => {
      const result = await createCampus(data);
      if (!result.error) {
        return props.onSuccess('Sede creada con éxito');
      }
      props.onError('create', result.error.data.message);
    },
    [props],
  );

  const updateCampusAction = useCallback(
    async (params: UseActionsParams['updateCampus']) => {
      const result = await updateCampus(params.campusId, params.data);
      if (!result.error) {
        return props.onSuccess('Sede modificada con éxito');
      }
      props.onError('update', result.error.data);
    },
    [props],
  );

  const deleteCampusAction = useCallback(
    async (campusId: number) => {
      const offer = data?.offers.find((offer) => offer.id === campusId);
      if (offer) {
        return props.onError(
          'delete',
          'No se puede eliminar la sede porque tiene ofertas asociadas',
        );
      }
      const result = await deleteCampus(campusId);
      if (!result.error) {
        return props.onSuccess('Sede eliminada con éxito');
      }
      props.onError('delete', result.error.data.message);
    },
    [props],
  );

  return {
    createCampus: createCampusAction,
    updateCampus: updateCampusAction,
    deleteCampus: deleteCampusAction,
  };
}
