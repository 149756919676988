import request from '../../../api/request';
import { PaginationQuery } from '../../../types/pagination';
import { PeriodsResponse } from './type';

const PERIOD_MAINTAINER = {
  GET: '/mantainer-portal/maintainers/periods',
  POST: '/mantainer-portal/maintainers/periods',
  PUT: '/mantainer-portal/maintainers/periods',
};

export function getPeriodsList(params: PaginationQuery) {
  return request<PeriodsResponse>(PERIOD_MAINTAINER.GET, {
    method: 'GET',
    params: { ...params, page: params.page - 1 },
  });
}

export function createPeriod(data: any) {
  return request(PERIOD_MAINTAINER.POST, {
    method: 'POST',
    data,
  });
}

export function updatePeriod(id: number, data: any) {
  return request(`${PERIOD_MAINTAINER.PUT}/${id}`, {
    method: 'PATCH',
    data,
  });
}
