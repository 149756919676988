import React, { CSSProperties } from 'react';
import { Icon } from '@octano/global-ui';

interface EmailSelectorProps {
  label: string;
  emails: string[];
  placeholder?: string;
  onAddEmail: () => void;
  onRemoveEmail: (email: string) => void;
}

const containerStyle: CSSProperties = {
  marginBottom: '16px',
  position: 'relative',
};
const titleStyle: CSSProperties = {
  fontSize: '15px',
  color: '#adb5bd',
  marginBottom: '4px',
  textTransform: 'uppercase',
};
const badgesContainerStyle: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  marginTop: '8px',
};
const badgeStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: '#e1ecf4',
  color: '#0056b3',
  padding: '5px 10px',
  borderRadius: '4px',
};
const badgeTextStyle = { marginRight: '8px', fontSize: '14px' };
const closeIconStyle = {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
};

const inputBorderStyle: CSSProperties = {
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '5px',
  height: '100%',
  borderRadius: '4px',
  background: process.env.REACT_APP_BRAND_COLORS_SECONDARY || '4990D0',
  display: 'none',
};

const EmailSelector: React.FC<EmailSelectorProps> = ({
  label,
  emails,
  placeholder = 'Ingresa un correo',
  onAddEmail,
  onRemoveEmail,
}) => {
  const inputStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '16px 10px',
    backgroundColor: emails.length > 0 ? '#ffffff' : '#f4f4f4',
    color: '#6c757d',
    cursor: 'pointer',
    border: 'none',
    paddingLeft: '20px',
    boxShadow: emails.length > 0 ? '0px 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
    position: 'relative',
  };

  return (
    <div style={containerStyle}>
      <div onClick={onAddEmail} style={inputStyle}>
        {emails.length > 0 && (
          <div style={{ ...inputBorderStyle, display: 'block' }} />
        )}
        <span style={titleStyle}>{label}</span>
        <span>{placeholder}</span>
      </div>

      <div style={badgesContainerStyle}>
        {emails.map((email, index) => (
          <div key={index} style={badgeStyle}>
            <span style={badgeTextStyle}>{email}</span>
            <span onClick={() => onRemoveEmail(email)} style={closeIconStyle}>
              <Icon name="close" size={'10px'} />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmailSelector;
