import { ColumnTable, Icon } from '@octano/global-ui';
import Badge from '../parts/Badge';
import dayjs from 'dayjs';

export default function useColumns({
  onClickEdit,
}: {
  onClickEdit: (row: any) => void;
  onClickRemove: (row: any) => void;
}): ColumnTable[] {
  return [
    {
      columnName: 'code',
      headerText: 'Código',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'name',
      headerText: 'Nombre',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'startDate',
      headerText: 'Fecha inicio',
      cellFormat: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'endDate',
      headerText: 'Fecha termino',
      cellFormat: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'active',
      headerText: 'Estado',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => {
        return (
          <Badge
            text={row.active ? 'Activo' : 'Inactivo'}
            color={row.active ? '#CEF0DA' : '#F2C0BD'}
          />
        );
      },
    },
    {
      columnName: 'Acciones',
      headerText: 'Acciones',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <button className="btn" onClick={() => onClickEdit(row)}>
              <Icon color="primary" name="edit" />
            </button>
          </div>
        );
      },
    },
  ];
}
