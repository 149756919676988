import { ReactElement, useCallback } from 'react';
import {
  AuthenticationError,
  AxiosResult,
  AxiosResultDefaultError,
} from '../../../../../api/request';
import { createFetchContext } from '../../../../../components/contexts/FetchContextFactory';
import { CampusesResponse } from '../../type';
import { getCampusesList } from '../../api';
import { PaginationQuery } from '../../../../../types/pagination';

interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  PaginationQuery,
  CampusesResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function useCampusesLoader() {
  return useFetch();
}

export const CampusesLoaderConsumer = FetchConsumer;

export default function CampusesLoader({ children }: BaseLoaderProps) {
  const request = useCallback(
    async (
      query: PaginationQuery,
    ): Promise<AxiosResult<CampusesResponse, AxiosResultDefaultError>> => {
      return getCampusesList(query);
    },
    [],
  );

  return (
    <FetchProvider
      request={request}
      defaultQuery={{
        page: 1,
        itemsPerPage: 10,
      }}
      fetchImmediately
    >
      {children}
    </FetchProvider>
  );
}
