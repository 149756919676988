import AuthLayout from '../layouts/AuthLayout';
import SidenavLayout from '../layouts/SidenavLayout';
import { PermissionName } from '../types/auth';
import AccountActivationForm from '../views/account/activation/ActivateAccountForm';
import Login from '../views/account/login';
import PasswordRecoveryForm from '../views/account/recovery/PasswordRecoveryForm';
import CreateOffer from '../views/createOffers';
import DraftOffer from '../views/draftMaintainer';
import OffersMaintainer from '../views/offers-maintainer/offer-list';
import ActivateAccountFinish from '../views/account/activation/ActivateAccountFinish';
import PasswordRecoveryFinish from '../views/account/recovery/PasswordRecoveryFinish';
import Recruitment from '../views/recruitment/offer-list';
import OfferDetail from '../views/recruitment/offer-detail';
import RecruimentSteps from '../views/recruitment/postulation-review';
import PostulationDetail from '../views/recruitment/postulation-detail';
import ViewOfferDetail from '../views/offers-maintainer/offer-detail';
import PostulantsAuthorization from '../views/academic-authorization/postulants-authorization';
import PostulationDetailForAuthorizationScreen from '../views/academic-authorization/postulation-details';
import AuthorizedPostulantsScreen from '../views/hiring/authorized-postulants';
import PostulationDetailForHiringScreen from '../views/hiring/postulation-details';
import RequiredFilesScreen from '../views/hiring/required-files';
import AssignSectionsUsers from '../views/assign-section';
import RatesSettingsScreen from '../views/settings/rates';
import ContractViewfindersScreen from '../views/settings/contract-viewfinders';
import ChangePasswordScreen from '../views/account/password';
import HiringhistoryScreen from '../views/hiring/history';
import Reports from '../views/settings/reports';
import GeneralMaintainer from '../views/maintainers/GeneralMaintainer';
import AcademicMaintainer from '../views/maintainers/AcademicMaintainer';

export enum PathsLayouts {
  auth = '/auth',
  maintainer = '/maintainer',
  offersMaintainer = '/offer-maintainer',
  selectionAndRecruitment = '/recruitment',
  academicAuthorization = '/academic-authorization',
  hiring = '/hiring',
  settings = '/settings',
  profile = '/profile',
}

export type ViewRouteType = {
  path: string;
  component: () => JSX.Element;
};

export type RoutesByLayout = {
  // this keys are the paths of every layout
  [key in PathsLayouts]: {
    layoutComponent: (props: any) => JSX.Element;
    defaultPath: string;
    views: ViewRouteType[];
    requiredPermissions?: PermissionName[];
    allPermissionsRequired?: boolean;
    isAuthRoute?: boolean;
  };
};

/**
 * Contiene todos los layouts con sus vistas
 */
const routes: RoutesByLayout = {
  [PathsLayouts.auth]: {
    isAuthRoute: true,
    layoutComponent: AuthLayout,
    defaultPath: '/login',
    views: [
      {
        path: '/login',
        component: Login,
      },
      {
        path: '/account/activation',
        component: AccountActivationForm,
      },
      {
        path: '/account/activation/finish',
        component: ActivateAccountFinish,
      },
      {
        path: '/reset-password',
        component: PasswordRecoveryForm,
      },
      {
        path: '/account/recovery/finish',
        component: PasswordRecoveryFinish,
      },
    ],
  },
  [PathsLayouts.maintainer]: {
    layoutComponent: SidenavLayout,
    defaultPath: '/general',
    allPermissionsRequired: true,
    requiredPermissions: [PermissionName.maintainers],
    views: [
      {
        path: '/general',
        component: GeneralMaintainer,
      },
      {
        path: '/general/:tab',
        component: GeneralMaintainer,
      },
      {
        path: '/academics',
        component: AcademicMaintainer,
      },
      {
        path: '/academics/:tab',
        component: AcademicMaintainer,
      },
    ],
  },
  [PathsLayouts.offersMaintainer]: {
    layoutComponent: SidenavLayout,
    defaultPath: '/published-offers',
    requiredPermissions: [
      PermissionName.readOfferMaintainer,
      PermissionName.readOfferDraftMaintainer,
    ],
    allPermissionsRequired: false,
    views: [
      {
        path: '/published-offers',
        component: OffersMaintainer,
      },
      {
        path: '/create-offer/:offerDraftId?',
        component: CreateOffer,
      },
      {
        path: '/draft-offers',
        component: DraftOffer,
      },
      {
        path: '/published-offers/:offerId',
        component: ViewOfferDetail,
      },
    ],
  },
  [PathsLayouts.selectionAndRecruitment]: {
    layoutComponent: SidenavLayout,
    defaultPath: '/offer-list',
    requiredPermissions: [PermissionName.readRecruitment],
    allPermissionsRequired: false,
    views: [
      {
        path: '/offer-list',
        component: Recruitment,
      },
      {
        path: '/offer-detail/:offerId/postulation/:postulationId/details',
        component: PostulationDetail,
      },
      {
        path: '/offer-detail/:offerId/postulation/:postulationId',
        component: RecruimentSteps,
      },
      {
        path: '/offer-detail/:offerId',
        component: OfferDetail,
      },
    ],
  },

  [PathsLayouts.academicAuthorization]: {
    layoutComponent: SidenavLayout,
    defaultPath: '/postulants-authorization',
    requiredPermissions: [PermissionName.readAcademicAuthorization],
    allPermissionsRequired: false,
    views: [
      {
        path: '/postulants-authorization',
        component: PostulantsAuthorization,
      },
      {
        path: '/users/:userId/postulations/:postulationId',
        component: PostulationDetailForAuthorizationScreen,
      },
    ],
  },

  [PathsLayouts.hiring]: {
    layoutComponent: SidenavLayout,
    defaultPath: '/authorized-postulants',
    requiredPermissions: [PermissionName.readHiring],
    allPermissionsRequired: false,
    views: [
      {
        path: '/authorized-postulants',
        component: AuthorizedPostulantsScreen,
      },
      {
        path: '/users/:userId/required-files',
        component: RequiredFilesScreen,
      },
      {
        path: '/users/:userId/postulations/:postulationId',
        component: PostulationDetailForHiringScreen,
      },
      {
        path: '/assign-sections',
        component: AssignSectionsUsers,
      },
      {
        path: '/history',
        component: HiringhistoryScreen,
      },
    ],
  },

  [PathsLayouts.settings]: {
    layoutComponent: SidenavLayout,
    defaultPath: '/grades',
    requiredPermissions: [PermissionName.readSettings],
    allPermissionsRequired: false,
    views: [
      {
        path: '/contract-viewfinders',
        component: ContractViewfindersScreen,
      },
      {
        path: '/rates',
        component: RatesSettingsScreen,
      },
      {
        path: '/reports',
        component: Reports,
      },
    ],
  },

  [PathsLayouts.profile]: {
    layoutComponent: SidenavLayout,
    defaultPath: '/password',
    views: [
      {
        path: '/password',
        component: ChangePasswordScreen,
      },
    ],
  },
};

export default routes;
