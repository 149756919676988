import { ReactElement, useCallback } from 'react';
import {
  AuthenticationError,
  AxiosResult,
  AxiosResultDefaultError,
} from '../../../../../api/request';
import { createFetchContext } from '../../../../../components/contexts/FetchContextFactory';
import Loading from '../../../../../components/info/Loading';
import { getFormData } from '../../api';
import { CampusesFiltersResponse } from '../../type';
import DisplayError from '../../../../../components/info/DisplayError';

interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  CampusesFiltersResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function useCampusesFilterLoader() {
  return useFetch();
}

export const CampusesFilterLoaderConsumer = FetchConsumer;

export default function CampusesFilterLoader({ children }: BaseLoaderProps) {
  const request = useCallback(async (): Promise<
    AxiosResult<CampusesFiltersResponse, AxiosResultDefaultError>
  > => {
    return getFormData();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ loading, data, error, refresh }) => {
          if (loading) {
            return <Loading />;
          } else if (error) {
            return (
              <DisplayError typeError={error.code} retryAction={refresh} />
            );
          } else if (data) {
            return children;
          } else {
            return null;
          }
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
