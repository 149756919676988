import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Icon,
  Modal,
  Select,
  SelectOptionType,
  TextInput,
} from '@octano/global-ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row, UncontrolledTooltip } from 'reactstrap';
import { Campus, IEmails } from '../../type';
import { useCampusesLoader } from '../Loaders/CampusesLoader';
import AddEmailModal, { AddEmailModalMethods } from './AddEmailModal';
import EmailSelector from '../EmailSelector';
import { useCampusesFilterLoader } from '../Loaders/CampusesFilterLoader';
import { useFilteredCommunes } from '../../hooks/useFiltersCommunes';
import { useValidations } from '../../../../../hooks/useValidations';

interface Props {
  actions: {
    createCampus: (data: any) => Promise<void>;
    updateCampus: (params: { campusId: number; data: any }) => Promise<void>;
  };
}

export interface CreateOrUpdateRef {
  closeModal: () => void;
  openModal: (row?: Campus) => void;
}

interface FormData {
  code: string;
  name: string;
  address: string;
  formCommune: SelectOptionType | undefined;
  formRegion: SelectOptionType | undefined;
  emails: IEmails | null;
}

const CreateOrUpdateModal = forwardRef<CreateOrUpdateRef, Props>(
  ({ actions }, ref) => {
    const { t } = useTranslation();
    const forms = useForm<FormData>();
    const {
      control,
      reset,
      watch,
      handleSubmit,
      formState: { isSubmitting },
    } = forms;
    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState<Campus | null>(null);
    const { data } = useCampusesLoader();
    const codes = data?.data.map((campus) => campus.code) ?? [];
    const { data: locationData } = useCampusesFilterLoader();
    const { validateTextNotEmpty, validateEmail } = useValidations();

    // Location
    const regionOptions =
      locationData?.regions.map((region) => ({
        label: region.name,
        value: region.id,
      })) || [];
    const communeOptions = locationData?.communes || [];

    const selectedRegion = watch('formRegion');
    const filteredCommunes = useFilteredCommunes(
      selectedRegion,
      communeOptions,
    );

    // Emails
    const selectedApplicantsModalRef = useRef<AddEmailModalMethods>(null);
    const authorizedForHiringModalRef = useRef<AddEmailModalMethods>(null);
    const rejectedInvitationsModalRef = useRef<AddEmailModalMethods>(null);

    const [selectedApplicantsEmails, setSelectedApplicantsEmails] = useState<
      string[]
    >([]);
    const [authorizedForHiringEmails, setAuthorizedForHiringEmails] = useState<
      string[]
    >([]);
    const [rejectedInvitationsEmails, setRejectedInvitationsEmails] = useState<
      string[]
    >([]);

    const handleAddSelectedApplicantEmail = (newEmail: string) => {
      setSelectedApplicantsEmails((prevEmails) => [...prevEmails, newEmail]);
    };

    const handleAddAuthorizedForHiringEmail = (newEmail: string) => {
      setAuthorizedForHiringEmails((prevEmails) => [...prevEmails, newEmail]);
    };

    const handleAddRejectedInvitationEmail = (newEmail: string) => {
      setRejectedInvitationsEmails((prevEmails) => [...prevEmails, newEmail]);
    };

    useEffect(() => {
      if (formData) {
        reset({
          code: formData.code,
          name: formData.name,
          address: formData.address,
          formCommune: {
            value: formData.commune?.id,
            label: formData.commune?.name,
          },
          formRegion: {
            value: formData.commune?.region?.id,
            label: formData.commune?.region?.name,
          },
          emails: formData.emails,
        });
      } else {
        reset({
          code: undefined,
          name: undefined,
          address: undefined,
          formCommune: undefined,
          formRegion: undefined,
          emails: undefined,
        });
      }
    }, [formData, reset]);

    useEffect(() => {
      if (formData) {
        setSelectedApplicantsEmails(formData.emails?.applicationSelected || []);
        setAuthorizedForHiringEmails(
          formData.emails?.applicationAuthorized || [],
        );
        setRejectedInvitationsEmails(
          formData.emails?.invitedToApplyRejected || [],
        );
      } else {
        setSelectedApplicantsEmails([]);
        setAuthorizedForHiringEmails([]);
        setRejectedInvitationsEmails([]);
      }
    }, [formData]);

    useImperativeHandle(ref, () => ({
      openModal(row?: Campus) {
        setFormData(row || null);
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    const onSubmit = async (values: FormData) => {
      const data = {
        id: formData ? formData.id : undefined,
        code: values.code,
        name: values.name,
        address: values.address,
        communeId: values.formCommune?.value,
        emails: {
          contact: values.emails?.contact,
          applicationSelected: selectedApplicantsEmails,
          applicationAuthorized: authorizedForHiringEmails,
          invitedToApplyRejected: rejectedInvitationsEmails,
        },
      };
      if (formData) {
        await actions.updateCampus({
          campusId: formData.id,
          data,
        });
      } else {
        await actions.createCampus(data);
      }

      reset();
      setModal(false);
    };

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid>
          <h1 className="text-dark fs-20 mt-3 mb-4 text-center">
            {formData ? 'Editar Sede' : 'Ingresar nueva Sede'}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6} xs={12}>
                <TextInput
                  name="code"
                  label={'Código'}
                  control={control}
                  rules={{
                    required: t('common.validations.required'),
                    validate: (value) =>
                      typeof value === 'string' &&
                      value &&
                      codes.includes(value) &&
                      formData?.code !== value
                        ? t('El código ya se encuentra en uso')
                        : true,
                  }}
                />
              </Col>
              <Col md={6} xs={12}>
                <TextInput
                  name="name"
                  label={'Nombre'}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>
              <Col md={12} xs={12}>
                <TextInput
                  name="address"
                  label={'Dirección'}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>

              {/* Región */}
              <Col md={6} xs={12}>
                <Select
                  name="formRegion"
                  label={t('Región')}
                  options={regionOptions}
                  control={control}
                  rules={{ required: true }}
                />
              </Col>

              {/* Comuna */}
              <Col md={6} xs={12}>
                <Select
                  name="formCommune"
                  label={t('Comuna')}
                  options={filteredCommunes}
                  control={control}
                  rules={{ required: true }}
                  disabled={!selectedRegion}
                />
              </Col>

              {/* Emails */}
              <Col md={12} xs={12}>
                <div style={{ position: 'relative' }}>
                  <TextInput
                    name="emails.contact"
                    label={'Email de Sede'}
                    control={control}
                    rules={{
                      validate: {
                        empty: validateTextNotEmpty,
                        email: validateEmail,
                      },
                    }}
                  />
                  <span
                    id="tooltip-email-contact"
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                    }}
                  >
                    <Icon name="information" />
                  </span>
                  <UncontrolledTooltip
                    placement="bottom"
                    fade={false}
                    target="tooltip-email-contact"
                  >
                    Utilizado en los correos de información al postulante, como
                    email de contacto de la sede
                  </UncontrolledTooltip>
                </div>
              </Col>

              <Col md={12} className="mt-4">
                <EmailSelector
                  label="Emails para Notificación de Postulaciones Seleccionadas (opcional)"
                  emails={selectedApplicantsEmails}
                  onAddEmail={() => selectedApplicantsModalRef.current?.open()}
                  onRemoveEmail={(email) =>
                    setSelectedApplicantsEmails((prevEmails) =>
                      prevEmails.filter((e) => e !== email),
                    )
                  }
                />
              </Col>

              <Col md={12} className="mt-4">
                <EmailSelector
                  label="Emails para Notificación de Post. Autorizadas para Contratación (opcional)"
                  emails={authorizedForHiringEmails}
                  onAddEmail={() => authorizedForHiringModalRef.current?.open()}
                  onRemoveEmail={(email) =>
                    setAuthorizedForHiringEmails((prevEmails) =>
                      prevEmails.filter((e) => e !== email),
                    )
                  }
                />
              </Col>

              <Col md={12} className="mt-4">
                <EmailSelector
                  label="Emails para Notificación de Invitaciones Rechazadas (opcional)"
                  emails={rejectedInvitationsEmails}
                  onAddEmail={() => rejectedInvitationsModalRef.current?.open()}
                  onRemoveEmail={(email) =>
                    setRejectedInvitationsEmails((prevEmails) =>
                      prevEmails.filter((e) => e !== email),
                    )
                  }
                />
              </Col>
            </Row>

            <Row className="mt-5">
              <Col xs={12} md={6}>
                <Button
                  outlined
                  text={'Cancelar'}
                  fullwidth
                  onClick={() => setModal(false)}
                  className="mb-3"
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  type="submit"
                  text={'Guardar'}
                  className="mb-3"
                  fullwidth
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </form>
          <AddEmailModal
            ref={selectedApplicantsModalRef}
            onConfirm={handleAddSelectedApplicantEmail}
          />
          <AddEmailModal
            ref={authorizedForHiringModalRef}
            onConfirm={handleAddAuthorizedForHiringEmail}
          />
          <AddEmailModal
            ref={rejectedInvitationsModalRef}
            onConfirm={handleAddRejectedInvitationEmail}
          />
        </Container>
      </Modal>
    );
  },
);

export default CreateOrUpdateModal;
