import { useCallback } from 'react';
import { createPeriod, updatePeriod } from '../api';

interface UseActionsParams {
  createPeriod: any;
  updatePeriod: { periodId: number; data: any };
}
type ActionType =
  | 'create'
  | 'update'
  | 'delete'
  | 'Periodo creado con éxito'
  | 'Periodo modificado con éxito';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const createPeriodAction = useCallback(
    async (data: UseActionsParams['createPeriod']) => {
      const result = await createPeriod(data);
      if (!result.error) {
        return props.onSuccess('Periodo creado con éxito');
      }
      props.onError('create', result.error.data.message);
    },
    [props],
  );

  const updatePeriodAction = useCallback(
    async (params: UseActionsParams['updatePeriod']) => {
      const result = await updatePeriod(params.periodId, params.data);
      if (!result.error) {
        return props.onSuccess('Periodo modificado con éxito');
      }
      props.onError('update', result.error.data.message);
    },
    [props],
  );

  return {
    createPeriod: createPeriodAction,
    updatePeriod: updatePeriodAction,
  };
}
