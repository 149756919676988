import { memo } from 'react';
import { Container } from 'reactstrap';
import CampusesLoader from './parts/Loaders/CampusesLoader';
import CampusesTable from './parts/CampusesTable';
import CampusesFilterLoader from './parts/Loaders/CampusesFilterLoader';

function Campuses() {
  return (
    <Container fluid className="mt-4">
      <CampusesFilterLoader>
        <CampusesLoader>
          <CampusesTable />
        </CampusesLoader>
      </CampusesFilterLoader>
    </Container>
  );
}
export default memo(Campuses);
