import { memo } from 'react';
import { Container } from 'reactstrap';
import PeriodsLoader from './parts/Loaders/PeriodsLoader';
import PeriodsTable from './parts/PeriodsTable';
function Periods() {
  return (
    <Container fluid className="mt-4">
      <PeriodsLoader>
        <PeriodsTable />
      </PeriodsLoader>
    </Container>
  );
}
export default memo(Periods);
