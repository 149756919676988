import request from '../../../api/request';
import { PaginationQuery } from '../../../types/pagination';
import { CampusesFiltersResponse, CampusesResponse } from './type';

const CAMPUS_MAINTAINER = {
  FORM_DATA: '/mantainer-portal/maintainers/campuses/form-data',
  GET: '/mantainer-portal/maintainers/campuses',
  POST: '/mantainer-portal/maintainers/campuses',
  PUT: '/mantainer-portal/maintainers/campuses',
  DELETE: (campusId: number) =>
    `/mantainer-portal/maintainers/campuses/${campusId}`,
};

export function getCampusesList(params: PaginationQuery) {
  return request<CampusesResponse>(CAMPUS_MAINTAINER.GET, {
    method: 'GET',
    params: { ...params, page: params.page - 1 },
  });
}

export function createCampus(data: any) {
  return request(CAMPUS_MAINTAINER.POST, {
    method: 'POST',
    data,
  });
}

export function updateCampus(id: number, data: any) {
  return request(`${CAMPUS_MAINTAINER.PUT}/${id}`, {
    method: 'PATCH',
    data,
  });
}

export function deleteCampus(sectionId: number) {
  return request(CAMPUS_MAINTAINER.DELETE(sectionId), {
    method: 'DELETE',
  });
}

export function getFormData() {
  return request<CampusesFiltersResponse>(CAMPUS_MAINTAINER.FORM_DATA, {
    method: 'GET',
  });
}
